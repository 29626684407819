import { computed, defineComponent, onMounted, ref, getCurrentInstance} from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { Field, ErrorMessage, FieldArray, useForm, useFieldArray } from "vee-validate";
import { Path} from "@/router/enums/RouterEnums";
import {  useRouter, useRoute } from "vue-router";
import {AlertMessage} from "@/bundle/AlertMessage"
import ApiService from "@/core/services/ApiService"
import Yup from "@/bundle/validations/YupExpended";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as JWT from "@/core/services/JwtService";
import { useFunctions } from "@/composables/useFunctions";
import ResponsableTechniquePanel from "./ResponsableTechniquePanel.vue";


export interface ResponsableTechnique
{
    email: string;
    id?: string;
    nom: string;
    prenom: string;
    telephone?: string;
    telephone2?: string;
    fonction?: string;
}
interface Declaration {
  id: string|undefined,
  exercice: number | undefined,
  permissionnaire : {
    id: string|undefined,
  },
  responsableTechniques : Array<ResponsableTechnique>,
  numeroLicence: string,
}

export default defineComponent({
  name: "declaration-new",
  components: {
    Field,
    ErrorMessage,
    FieldArray,
    ResponsableTechniquePanel
  },
  
  setup() {

    onMounted(() => {
      setCurrentPageBreadcrumbs("Nouvelle déclaration", ["Déclarations"]);
    });


    const router = useRouter();
    const route = useRoute();
    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const {listYears, currentYear} = useFunctions();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const buttonSubmitted = ref<boolean>(false);
    const refreshKey = 0;
    const declarationId = ref<any>(null);
    const title = ref<string>('Nouvelle Déclaration');
    const loading = ref<boolean>(false);

    const declaration = ref<Declaration>({
      id : undefined,
      exercice: undefined,
      permissionnaire : {
        id: JWT.getAuthUser()?.id,
      },
      responsableTechniques : [],
      numeroLicence:"",
    });

    const years = computed(() => {
      return listYears();
    });

    /* Pour l'ajout ou la sélection des responsables technqiues */
    const selectedResponsables = ref<any>([]); //liste des éléments sélectionnés
    const addedResponsables = ref<any>([]); // liste des éléments ajoutés au formulaire
    const responsables = ref<any>([]); // liste de tous les responsables

    let schema = Yup.object().shape({
      id: Yup.string().default(undefined),
      exercice : Yup.number().required().label("L'exercice"),
      permissionnaire: Yup.object().shape(
        {
          id:Yup.string().required().label("Le numéro de licence").default(JWT.getAuthUser()?.id)
        }
      ),
      responsableTechniques: Yup.array().of(
        Yup.object().shape({
          nom : Yup.string().required().min(2).max(60).label("Le nom"),
          prenom : Yup.string().required().min(2).max(60).label("Le prénom"),
          id : Yup.string().notRequired().label("L'id"),
          email : Yup.string().required().email().label("L'adresse e-mail"),
          telephone : Yup.string().numerotationGabonaise().required().label("Le numéro de téléphone"),
          telephone2 : Yup.string().notRequired().numerotationGabonaise().label("Le numéro de téléphone"),
          fonction : Yup.string().label("La fonction"),
        })
      ).min(1,'La liste des responsables techniques doit avoir au moins 1 élèment' )
       .label("La liste des responsables techniques"),
      numeroLicence : Yup.string().required().label("Le numéro de licence"),
    });


    const { resetForm, handleSubmit, errors, setErrors, meta } = useForm<Declaration>({
      validationSchema: schema,
      initialValues : declaration.value
    });



    /**
     * permet de concatener les responsables ajoutés et ceux sélectionnés
     */
    const concatArray = () => {
      return selectedResponsables.value.concat(addedResponsables.value);
    }

    /**
     * retirer le responsable  technique de la liste
     */
    const removeSelected = (item) => {
      if(item.value.id){
        let index = selectedResponsables.value.findIndex(x => x.id === item.value.id);
        if(index !== -1){
          selectedResponsables.value.splice(index, 1);
        }
      }else{
        let index = addedResponsables.value.findIndex(x => x.nom+x.prenom === item.value.nom+item.value.prenom);
        if(index !== -1){
          addedResponsables.value.splice(index, 1);
        }
      }
      //remove(item);  
    }

    const editResponsable = (item) => {
      emitter.emit('open-edit-responsable', item);
    }

    const formSubmit = handleSubmit( (values) => {
      let path = "/api/declaration";

      buttonSubmitted.value = true;
      let data: any=Object.assign({}, values);

      //On retire le champs permissionnaire associé au rensponsable technique
      // data.responsableTechniques.forEach((el) => {
      //   el.permissionnaire = undefined;
      //   el.id = undefined
      // })

      setTimeout(() => {
        if(!declarationId.value){
          ApiService.post(path, data)
            .then( (response) => 
              {
                buttonSubmitted.value = false;
                var title = "Déclaration enregistrée"
                var message = "<stong>Félicitation</strong>! Vous venez d'enregistrer votre déclaration!"
                AlertMessage.onSuccess(message, title).then(() => {
                  router.push({name:"declaration-details", params: {id: response.data.id}});
                });
              })
            .catch((error) => {
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              buttonSubmitted.value = false;
              return false;
            });
        }else{
          path = `/api/declaration/${declarationId.value}`
          ApiService.put(path, data)
            .then( (response) => 
              {
                buttonSubmitted.value = false;
                var title = "Modifications"
                var message = "<stong>Félicitation</strong>! Modifications enregistrées!"
                AlertMessage.onSuccess(message, title);
              })
            .catch((error) => {
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              buttonSubmitted.value = false;
              return false;
            });
        }
        
      }, 500);

      
    });

    const getDeclaration = (id) => {
      loading.value = true;
    
      ApiService.get(`/api/declaration/${id}`).then((res: any) => {
        loading.value = false;
        declaration.value = res.data;

        initForm(res.data);
        
      }).catch(error => { 
        loading.value = false;
        router.push({name: '404'})
      });
    }

    const initForm = (data: Declaration) => {
      resetForm({
        values: {
          id : data.id,
          exercice: data.exercice,
          permissionnaire : {
            id: JWT.getAuthUser()?.id,
          },
          responsableTechniques : data.responsableTechniques,
          numeroLicence: data.numeroLicence,
        }
      });
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs("Nouvelle déclaration", ["Déclarations"]);

      declarationId.value = route.params.id as string;
      if(declarationId.value){
        setCurrentPageBreadcrumbs("Modifier la déclaration", ["Déclarations"]);
        
        title.value = "Modifier la déclaration"
        getDeclaration(declarationId.value);
      }
    })

    return {
      editResponsable,
      removeSelected,
      concatArray,
      formSubmit,
      getDeclaration,
      initForm,
      title,
      years,
      declaration,
      selectedResponsables,
      addedResponsables,
      responsables,
      submitButtonRef,
      errors,
      schema,
      meta,
      buttonSubmitted
    };
  }
});