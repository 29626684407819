
//Plugin pour fonctions sur les nombres
export function  useFunctions () {
  
  const listYears = () => {
    let start = 1990;
    let now = new Date().getFullYear();
    let datas:any[] = [];

    for(var i: number = start; i <= now + 1; i++){
      datas.push(i);
    }
    return datas.sort().reverse();
  }

  const currentYear = () => {
    let current = new Date().getFullYear();

    return current;
  }

  return {
    listYears,
    currentYear
  }
}